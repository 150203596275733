dmx.Component('sqlite-select', {

  initialData: {
    data: [],
    state: {
      executing: false,
    },
    lastError: {
      message: '',
    },
  },

  attributes: {
    database: {
      type: String,
    },

    params: {
      type: Object,
      default: {}
    },

    noload: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    load (params) {
      this._query(params);
    }
  },

  events: {
    start: Event, // query start
    done: Event, // query done
    error: Event, // query error
    success: Event, // query ok
  },

  $parseAttributes: function(node) {
    dmx.BaseComponent.prototype.$parseAttributes.call(this, node);
    dmx.dom.getAttributes(node).forEach(attr => {
      if (attr.name == 'param' && attr.argument) {
        this.$addBinding(attr.value, value => {
          this.props.params[attr.argument] = value;
          this.props.params = dmx.clone(this.props.params);
        });
      }
    });
  },

  init (node) {
    try {
      this._ast = JSON.parse(node.textContent);
      this._ast.type = 'select';
      
      if (!this.props.noload) {
        this._query();
      }
    } catch (err) {
      console.error(err);
      this.set('lastError', { message: err.toString() });
    }
  },

  performUpdate (updatedProps) {
    if (!this.props.noload && updatedProps.has('params')) {
      this._query();
    }
  },

  _query (extra = {}) {
    if (!this._ast) return;

    const params = dmx.extend(this.props.params, extra);
    const scope = new dmx.DataScope(params, this);
    const sql = dmx.ast2sql(this._ast);

    this.dispatchEvent('start');
    this.set('state', { executing: true });

    dmx.sqlite.query({
      database: this.props.database,
      statement: sql.statement,
      values: sql.values.map(value => (typeof value == 'string' && value.startsWith('{{')) ? dmx.parse(value, scope) : value)
    }).then(result => {
      this._processResult(result);
    }).then(data => {
      this.set('data', data);
      this.dispatchEvent('success');
      this._done();
    }).catch(err => this._done(err));
  },

  _processResult (result) {
    return result.values.slice(Capacitor.getPlatform() == 'ios' ? 1 : 0);
  },

  _done (err) {
    if (err) {
      this.set('lastError', { message: err.toString() });
      this.dispatchEvent('error')
    }

    this.set('state', { executing: false });
    this.dispatchEvent('done');
  },

});