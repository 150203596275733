dmx.Component('sqlite-exec', {

  initialData: {
    affected: 0,
    identity: null,
    state: {
      executing: false,
    },
    lastError: {
      message: '',
    },
  },

  attributes: {
    database: {
      type: String,
    },
  },

  methods: {
    run () {
      this._run();
    }
  },

  events: {
    start: Event, // query start
    done: Event, // query done
    error: Event, // query error
    success: Event, // query ok
  },

  init (node) {
    try {
      this._ast = JSON.parse(node.textContent);
    } catch (err) {
      console.error(err);
      this.set('lastError', { message: err.toString() });
    }
  },

  _run () {
    if (!this.ast) return;

    const sql = dmx.ast2sql(this._processResult(this._ast));

    this.dispatchEvent('start');
    this.set('state', { executing: true });

    dmx.sqlite.run({
      database: this.props.database,
      statement: sql.statement,
      values: sql.values.map(value => (typeof value == 'string' && value.startsWith('{{')) ? dmx.parse(value, this) : value)
    }).then(result => {
      this._processResult(result);
    }).then(result => {
      this.set(result);
      this.dispatchEvent('success');
      this._done();
    }).catch(err => this._done(err));
  },

  _processAst(ast) {
    return ast;
  },

  _processResult(result) {
    return {
      affected: result.changes.changes,
      identity: result.changes.lastId
    };
  },

  _done: function(err) {
    if (err) {
      this.set('lastError', { message: err.toString() });
      this.dispatchEvent('error')
    }

    this.set('state', { executing: false });
    this.dispatchEvent('done');
  },

});