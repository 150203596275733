dmx.sqlite = Capacitor.Plugins.CapacitorSQLite;

dmx.Startup(new Promise((resolve, reject) => {
  document.addEventListener('DOMContentLoaded', () => {
    const platform = Capacitor.getPlatform();

    if (platform == 'web') {
      if (!document.querySelector('script[src*="jeep-sqlite"]')) {
        return reject(Error('jeep-sqlite not detected'));
      }
      const jeep = document.createElement('jeep-sqlite');
      jeep.setAttribute('autoSave', 'true');
      document.body.append(jeep);
      if (dmx.debug) console.debug('Init jeep sqlite component');
      customElements.whenDefined('jeep-sqlite').then(() => {
        if (dmx.debug) console.debug('Init Web Store');
        dmx.sqlite.initWebStore().then(resolve);
      });
    } else {
      if (dmx.debug) console.debug('Native platform');
      resolve();
    }
  });
}).then(() => {
  // check for upgrade statements
  if (dmx.debug) console.debug('Init databases', dmx.databases);
  if (dmx.databases && Object.keys(dmx.databases).length) {
    return Promise.all(Object.keys(dmx.databases).filter(database => {
      return dmx.databases[database].type == null || dmx.databases[database].type == 'sqlite';
    }).map(database => {
      const { version, upgrade } = dmx.databases[database];

      if (dmx.debug) console.debug(`Init database "${database}" version ${version}`);

      return Promise.all(upgrade.map(upgrade => {
        if (dmx.debug) console.debug(`Adding upgrade statement for ${database}:`, upgrade);
        return dmx.sqlite.addUpgradeStatement({ database, upgrade: [upgrade] });
      })).then(() => {
        if (dmx.debug) console.debug(`Connect to database ${database} version ${version}`);
        return dmx.sqlite.createConnection({ database, version });
      }).then(() => {
        if (dmx.debug) console.debug(`Open database ${database}`);
        return dmx.sqlite.open({ database });
      }).then(() => {
        if (dmx.debug) console.debug(`Database ${database} ready`);
      });
    }));
  }
}));

dmx.ast2sql = function(ast) {
  const clauses = [];
  const params = [];

  function identifier(name) {
    return `"${name}"`;
  }

  function distinct(ast) {
    return ast.distinct ? 'DISTINCT' : '';
  }

  function table(obj) {
    return identifier(obj.table.name || obj.table || ast.table.name || ast.table);
  }

  function column(column) {
    return (column.aggregate ? column.aggregate + '(' : '')
      + (column.distinct ? 'DISTINCT ' : '')
      + (ast.joins && ast.joins.length ? table(column) + '.' : '')
      + identifier(column.column)
      + (column.aggregate ? ')' : '')
      + (column.alias ? ' AS ' + identifier(column.alias) : '');
  }

  function columns(ast) {
    if (Array.isArray(ast.columns) && ast.columns.length) {
      return ast.columns.map(column).join(', ');
    } else {
      return '*';
    }
  }

  function from(ast) {
    return 'FROM '
      + identifier(ast.table.name || ast.table)
      + (ast.table.alias ? ` AS ${identifier(ast.table.alias)}` : '');
  }

  function join(ast) {
    if (!ast.joins || !ast.joins.length) return '';
    return ast.joins.map(join => {
      return (join.type ? join.type + ' ' : '')
        + `JOIN ${identifier(join.table.name || join.table)}`
        + (join.table.alias || join.alias ? ` AS ${identifier(join.table.alias || join.alias)}` : '')
        + (join.clauses ? ` ON ${expression(join.clauses)}` : '');
    }).join(' ');
  }

  function where(ast) {
    if (!ast.wheres || !ast.wheres.condition || !ast.wheres.rules.length) return '';
    return `WHERE ${ast.wheres.rules.map(expression).join(` ${ast.wheres.condition} `)}`;
  }

  function groupBy(ast) {
    if (!ast.groupBy || !ast.groupBy.length) return '';
    return 'GROUP BY ' + ast.groupBy.map(group => {
      return (ast.joins && ast.joins.length ? table(column) + '.' : '') + identifier(group.column);
    }).join(', ');
  }

  function having(ast) {
    if (!ast.having) return '';
    return `HAVING ${expression(ast.having)}`;
  }

  function orderBy(ast) {
    if (!ast.orders || !ast.orders.length) return '';
    return 'ORDER BY ' + ast.orders.map(order => {
      return (ast.joins && ast.joins.length ? table(column) + '.' : '')
        + identifier(order.column)
        + (order.direction ? ` ${order.direction}` : '');
    }).join(', ')
  }

  function limit(ast) {
    if (!ast.limit) return '';
    return `LIMIT ${ast.limit}`;
  }

  function offset(ast) {
    if (ast.offset == null) return '';
    return `OFFSET ${ast.offset}`;
  }

  function values(ast) {
    return `(${ast.values.map(val => identifier(val.column)).join(', ')})`
      + ' VALUES '
      + `(${ast.values.map(val => value(val.value)).join(', ')})`;
  }

  function set(ast) {
    return 'SET ' + ast.values.map(val => {
      return `${identifier(val.column)} = ${value(val.value)}`;
    });
  }

  function expression(ast) {
    if (!ast.condition) {
      let val = '';

      if (!ast.operation && ast.operator) {
        ast.operation = ({
          equal: '=',
          not_equal: '<>',
          less: '<',
          less_or_equal: '<=',
          greater: '>',
          greater_or_equal: '>=',
          in: 'IN',
          not_in: 'NOT IN',
          between: 'BETWEEN',
          not_between: 'NOT BETWEEN',
          begins_with: 'LIKE',
          not_begins_with: 'NOT LIKE',
          contains: 'LIKE',
          not_contains: 'NOT LIKE',
          ends_with: 'LIKE',
          not_ends_with: 'NOT LIKE',
          is_empty: '=',
          is_not_empty: '<>',
          is_null: 'IS NULL',
          is_not_null: 'IS NOT NULL'
        })[ast.operator];
      }

      if (ast.operator == 'is_empty' || ast.operator == 'is_not_empty') {
        ast.value = '';
      }

      if (/^IS\s+(NOT\s+)?NULL$/i.test(ast.operation)) {
        return `${column(ast.data || ast)} ${ast.operation}`;
      } else if (/^(NOT\s+)?BETWEEN$/i.test(ast.operation)) {
        val = `${value(ast.value[0])} AND ${value(ast.value[1])}`;
      } else if (/^(NOT\s+)?IN$/i.test(ast.operation)) {
        if (Array.isArray(ast.value)) {
          val = `(${ast.value.map(value).join(', ')})`;
        } else {
          val = `(${ast.value})`;
        }
      } else {
        val = ast.value != null ? value(ast.value) : ast.value;
      }

      return `${column(ast.data || ast)} ${ast.operation} ${val}`;
    }

    return `(${ast.rules.map(expression).join(` ${ast.condition} `)})`;
  }

  function value(value, extra) {
    if (value == null) return 'NULL';

    if (typeof value == 'object') {
      return (value.table ? table(value) + '.' : '')
        + identifier(value.column);
    }

    params.push(value);

    return '?';
  }

  switch (ast.type.toUpperCase()) {
    case 'SELECT':
      clauses.push('SELECT');
      clauses.push(distinct(ast));
      clauses.push(columns(ast));
      clauses.push(from(ast));
      clauses.push(join(ast));
      clauses.push(where(ast));
      clauses.push(groupBy(ast));
      clauses.push(having(ast));
      clauses.push(orderBy(ast));
      clauses.push(limit(ast));
      clauses.push(offset(ast));
      break;

    case 'INSERT':
      clauses.push('INSERT INTO');
      clauses.push(table(ast));
      clauses.push(values(ast));
      break;

    case 'UPDATE':
      clauses.push('UPDATE');
      clauses.push(table(ast));
      clauses.push(set(ast));
      clauses.push(where(ast));
      break;

    case 'DELETE':
      clauses.push('DELETE');
      clauses.push(from(ast));
      clauses.push(where(ast));
      break;
  }

  return {
    statement: clauses.filter(clause => !!clause).join(' '),
    values: params
  }
};