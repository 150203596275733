dmx.Actions({

  /**
   * Connect to sqlite database
   * @param {Object} options  - capSQLiteOptions
   * @param {string} options.database - The database name
   * @param {boolean} options.readonly - Set to true (database in read-only mode) / false
   * @returns {undefined}
   */
  'Capacitor.sqlite.connect': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.createConnection(parsed).then(() => dmx.sqlite.open(parsed));
  },

  /**
   * Get version
   * @param {Object} options  - capSQLiteOptions
   * @param {string} options.database - The database name
   * @param {boolean} options.readonly - Set to true (database in read-only mode) / false
   * @returns {number}
   */
  'Capacitor.sqlite.getVersion': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.getVersion(parsed).then(result => result.version);
  },

  /**
   * Check if a SQLite database exists without connection
   * @param {Object} options  - capSQLiteOptions
   * @param {string} options.database - The database name
   * @param {boolean} options.readonly - Set to true (database in read-only mode) / false
   * @returns {boolean}
   */
  'Capacitor.sqlite.isDatabase': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.isDatabase(parsed).then(result => result.result);
  },

  /**
   * Check if a table exists in a SQLite database
   * @param {Object} options  - capSQLiteTableOptions
   * @param {string} options.database - The database name
   * @param {string} options.table - The table name
   * @param {boolean} options.readonly - ReadOnly / ReadWrite default ReadWrite (false)
   * @returns {boolean}
   */
  'Capacitor.sqlite.isTableExists': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.isTableExists(parsed).then(ret => ret.result);
  },

  /**
   * Delete a SQLite database
   * @param {Object} options  - capSQLiteOptions
   * @param {string} options.database - The database name
   * @param {boolean} options.readonly - Set to true (database in read-only mode) / false
   * @returns {undefined}
   */
  'Capacitor.sqlite.deleteDatabase': function() {
    const parsed = this.parse(options);
    return dmx.sqlite.deleteDatabase(parsed);
  },

  /**
   * Copy databases from assets/databases folder to application databases folder
   * @param {Object} options  - capSQLiteFromAssetsOptions
   * @param {boolean} options.overwrite - Set the overwrite mode for the copy from assets "true"/"false" default to "true"
   * @returns {undefined}
   */
  'Capacitor.sqlite.copyFromAssets': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.copyFromAssets(parsed);
  },

  /**
   * Get the database list
   * @param {Object} options  - capSQLiteHTTPOptions
   * @param {string} options.url - The url of the database or the zipped database(s)
   * @param {boolean} options.overwrite - Set the overwrite mode for the copy from assets "true"/"false" default to "true"
   * @returns {undefined}
   */
  'Capacitor.sqlite.getFromHTTPRequest': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.getFromHTTPRequest(parsed);
  },

  /**
   * Get the database list
   * @returns {string[]} - list of databases
   */
  'Capacitor.sqlite.getDatabaseList': function() {
    return dmx.sqlite.getDatabaseList().then(result => result.values);
  },

  /**
   * Get the Migratable database list
   * @param {Object} options  - capSQLiteOptions
   * @param {string} options.database - The database name
   * @param {boolean} options.readonly - Set to true (database in read-only mode) / false
   * @returns {string[]} - list of tables
   */
  'Capacitor.sqlite.getTableList': function(options) {
    const parsed = this.parse(options);
    return dmx.sqlite.getTableList(parsed).then(result => result.values);
  },

  'Capacitor.sqlite.single': function(options) {
    const parsed = this.parse(options);

    parsed.sql.type = 'select';
    parsed.sql.limit = 1;

    const sql = dmx.ast2sql(parsed.sql);

    return dmx.sqlite.query({
      database: parsed.connection,
      statement: sql.statement,
      values: sql.values
    }).then(({values}) => {
      // the data values list as an Array iOS the first row is the returned ios_columns name list
      return values[Capacitor.getPlatform() == 'ios' ? 1 : 0];
    });
  },

  'Capacitor.sqlite.select': function(options) {
    const parsed = this.parse(options);

    parsed.sql.type = 'select';

    const sql = dmx.ast2sql(parsed.sql);

    return dmx.sqlite.query({
      database: parsed.connection,
      statement: sql.statement,
      values: sql.values
    }).then(({values}) => {
      // the data values list as an Array iOS the first row is the returned ios_columns name list
      return values.slice(Capacitor.getPlatform() == 'ios' ? 1 : 0);
    });
  },

  'Capacitor.sqlite.paged': function(options) {
    const parsed = this.parse(options);

    let offset = parsed.sql.offset || 0;
    let limit = parsed.sql.limit || 0;
    let total = 0;

    parsed.sql.type = 'select';

    const sql = dmx.ast2sql(parsed.sql);
    const sqlCount = dmx.ast2sql({ ...parsed.sql, offset: null, limit: null, orders: null });

    return dmx.sqlite.query({
      database: parsed.connection,
      statement: `SELECT COUNT(*) AS Total FROM (${sqlCount.statement})`,
      values: sqlCount.values,
    }).then(({values}) => {
      total = values[Capacitor.getPlatform() == 'ios' ? 1 : 0].Total;

      return dmx.sqlite.query({
        database: parsed.connection,
        statement: sql.statement,
        values: sql.values
      }).then(({values}) => {
        return {
          offset,
          limit,
          total,
          page: {
            offset: {
              first: 0,
              prev: offset - limit > 0 ? offset - limit : 0,
              next: offset + limit < total ? offset + limit : offset,
              last: (Math.ceil(total / limit) - 1) * limit,
            },
            current: Math.floor(offset / limit) + 1,
            total: Math.ceil(total / limit),
          },
          data: values.slice(Capacitor.getPlatform() == 'ios' ? 1 : 0),
        };
      });
    });
  },

  'Capacitor.sqlite.insert': function(options) {
    const parsed = this.parse(options);

    parsed.sql.type = 'insert';

    const sql = dmx.ast2sql(parsed.sql);

    return dmx.sqlite.run({
      database: parsed.connection,
      statement: sql.statement,
      values: sql.values
    }).then(({changes}) => {
      return { affected: changes.changes, identity: changes.lastId };
    });
  },

  'Capacitor.sqlite.update': function(options) {
    const parsed = this.parse(options);

    parsed.sql.type = 'update';

    const sql = dmx.ast2sql(parsed.sql);

    return dmx.sqlite.run({
      database: parsed.connection,
      statement: sql.statement,
      values: sql.values
    }).then(({changes}) => {
      return { affected: changes.changes, identity: changes.lastId };
    });
  },

  'Capacitor.sqlite.delete': function(options) {
    const parsed = this.parse(options);

    parsed.sql.type = 'delete';

    const sql = dmx.ast2sql(parsed.sql);

    return dmx.sqlite.run({
      database: parsed.connection,
      statement: sql.statement,
      values: sql.values
    }).then(({changes}) => {
      return { affected: changes.changes, identity: changes.lastId };
    });
  },

  'Capacitor.sqlite.custom': function(options) {
    const parsed = this.parse(options);

    const params = [];
    const query = parsed.sql.query.replace(/([:@][a-zA-Z_]\w*|\?)/g, param => {
      if (param == '?') {
        params.push(parsed.sql.params[params.length].value);
        return '?';
      }

      let p = parsed.sql.params.find(p => p.name == param);
      if (p) {
        params.push(p.value);
        return '?';
      }

      return param;
    });

    return dmx.sqlite.query({
      database: parsed.connection,
      statement: query,
      values: params
    }).then(({values}) => {
      // the data values list as an Array iOS the first row is the returned ios_columns name list
      return values.slice(Capacitor.getPlatform() == 'ios' ? 1 : 0);
    });
  },

  /**
   * Close a SQLite database
   * @param {Object} options  - capSQLiteOptions
   * @param {string} options.database - The database name
   * @param {boolean} options.readonly - Set to true (database in read-only mode) / false
   * @returns {undefined}
   */
  'Capacitor.sqlite.close': function(options) {
    return dmx.sqlite.closeConnection(this.parse(options));
  },

});